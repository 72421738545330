export default {
  EN: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverAfrica calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverAfrica",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverAfrica App functions.",
    "bottom.faq.p":
      "SaverAfrica has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "button.promo": "Promo",
    "button.details": "Details",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAfrica notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAfrica notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverAfrica",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  FR: {
    "topheader.compare.help": "Besoin d'aide? Découvrez nos guides vidéo",
    "carousel.item1.h2": "Gagnez du temps",
    "carousel.item2.h2": "Economisez de l'argent",
    "carousel.item3.h2": "Epargnez pour votre avenir",
    "carousel.item1.p":
      "Gagnez du temps en comparant rapidement les services de transfert d'argent - pour vous aider à trouver le meilleur prix et le meilleur produit lorsque vous envoyez de l'argent chez vous.",
    "carousel.item2.p":
      "Apprenez à économiser de l'argent en comparant les services de transfert d'argent et en utilisant les calculateurs de SaverAfrica.",
    "carousel.item3.p":
      "Apprenez à épargner pour votre avenir et à améliorer vos finances grâce à notre guide sur les bases en finance.",
    "button.start": "Démarrez",
    "topnav.compare.form.h1": "Comparez les tarifs",
    "topheader.compare.form.h2": "Economisez sur les transferts d'argent",
    "topheader.compare.form.p":
      "Comparez les coûts des entreprises offrant des services de transfert d'argent",
    "label.country_from": "Envoyez de",
    "label.country_to": "Envoyez vers",
    "label.how_much": "Combien",
    "button.compare": "Comparez les tarifs maintenant",
    "topheader.compare.list.h2": "Résultats de comparaison",
    "topheader.compare.list.green":
      "Les résultats sont en ligne dès aujourd'hui {date_aujourd'hui}",
    "topheader.compare.list.amber": "Résultats collectés le {date_mis à jour}",
    "topheader.compare.list.red":
      "Résultats collectés par le biais d'une enquête mystère le {date_mis à jour}",
    "topheader.compare.list.p":
      "Envoyez de l'argent de {pays_de} à {pays_vers} avec SaverAfrica",
    "bottomheader.compare.list.p":
      "Ces résultats peuvent ne pas inclure tous les services disponibles pour ce couloir de transfert de fonds. Les informations ci-dessus incluent tous les coûts connus.",
    "topnav.resource.h1": "Finance de base",
    "topheader.resource.form.h2": "Finance de base",
    "topheader.resource.form.p":
      "Pour vous aider à épargner ou à envoyer votre argent, veuillez lire nos conseils de base en finance.",
    "topnav.calculators.h1": "Calculatrices",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Calculateur de budget",
    "card2.calculators.p":
      "Ajoutez vos revenus et vos dépenses pour voir combien vous pourriez économiser et pour vous assurer que vous ne dépensez pas trop.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Fréquence des paiements",
    "label.amount": "Montant",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Dépenses totales",
    "label.total_income": "Revenu total",
    "label.money_left": "Argent restant",
    "label.daily": "Quotidien",
    "label.weekly": "Hebdomadaire",
    "label.monthly": "Mensuel",
    "label.item": "Article",
    "label.primary_income": "Revenu principal",
    "label.market_rate":
      "Le marché intermédiaire FX est {marché_taux} pour les résultats de données en direct",
    "label.operator": "Opérateur",
    "label.type": "Forme de transfert",
    "label.receiving": "Réception",
    "label.rate": "Taux FX",
    "label.fee": "Frais",
    "label.form": "Formulaire",
    "label.method": "Méthode de transfert",
    "label.speed": "Rapidité du transfert",
    "label.number_overtime": "Nombre d'heures supplémentaires travaillées",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Guide Vidéos",
    "topheader.video.form.p":
      "Veuillez utiliser nos guides vidéo pour vous aider à comprendre comment utiliser les fonctions de l'application SaverAfrica.",
    "bottom.faq.p":
      "SaverAfrica a les réponses à la plupart de vos questions, mais si vous avez besoin de plus d'informations, veuillez nous contacter à <a>enquiries@saver.global</a> et nous essaierons de vous aider",
    "notfound.h2": "Oh - Euh",
    "notfound.p":
      "Désolé, nous ne trouvons pas l'écran que vous recherchez. Utilisez la navigation ci-dessous",
    "topheader.summary.h2": "Résumé",
    "topheader.summary.p":
      "Vous pouvez voir ci-dessous combien d'argent vous devriez recevoir pour cette période",
    "label.no_results":
      "Aucun résultat contenant tous vos termes de filtre n'a été trouvé",
    "button.back": "Retour",
    "button.prev": "Précédent",
    "button.next": "Suivant",
    "button.remove": "Enlever",
    "button.add_income": "Ajouter un revenu",
    "button.add_expense": "Ajouter une dépense",
    "button.read_more": "En savoir plus",
    "button.show_less": "Montrer moins",
    "topnav.budget.h1": "Calculateur de budget",
    "topheader.income.h2": "Revenu",
    "topheader.income.p":
      "Commençons par saisir toutes les façons dont vous gagnez de l'argent. ",
    "topheader.expense.h2": "Dépenses",
    "topheader.expense.p":
      "Ajoutez vos dépenses ci-dessous. Par exemple, loyer, nourriture, envois de fonds, factures (téléphone, électricité, etc.) et voyages.",
    "topheader.budget_summary.p":
      "Bravo d'avoir terminé votre plan budgétaire, voyons combien d'argent vous avez économisé",
    "card.other_income.h4": "Autres revenus",
    "card.other_income.p":
      "Avez-vous d'autres revenus ? Par exemple, des allocations gouvernementales, un deuxième emploi, un revenu locatif, etc.",
    "topnav.setting.h1": "Paramètres",
    "label.language": "Langue",
    "label.currency": "Devise",
    "label.push_notification": "Notifications push",
    "label.language.desc": "Sélectionnez la langue de l'app",
    "label.currency.desc": "Sélectionnez la devise de l'app",
    "label.push_notification.desc": "Autoriser l'app à pousser notifications",
    "label.not_supported": "Non supporté",
    "button.view_all_operators": "Voir tous les opérateurs",
    "logo.supported.h3": "Supporté par",
    "logo.managed.h3": "Géré par",
    "label.view_legend": "Voir Légende",
    "label.legend": "Légende",
    "menu.about": "A propos",
    "menu.privacy_policy": "Politique de confidentialité",
    "menu.terms_and_conditions": "Termes et Conditions",
    "menu.disclaimer": "Mentions légales",
    "label.january": "Janvier",
    "label.february": "Février",
    "label.march": "Mars",
    "label.april": "Avril",
    "label.may": "Mai",
    "label.june": "Juin",
    "label.july": "Juillet",
    "label.august": "Août",
    "label.september": "Septembre",
    "label.october": "Octobre",
    "label.november": "Novembre",
    "label.december": "Décembre",
    "error.primary_income": "Le revenu principal est requis",
    "error.basic_wage": "Le salaire de base est requis",
    "button.apply": "Appliquer",
    "button.clear": "Effacer",
    "economic.hardship":
      "Des possibles difficultés économiques peuvent survenir si vous continuez à dépenser à ce rythme",
    "play.video": "Regarder la vidéo",
    "label.download": "Télecharger",
    "label.download_summary": "Télécharger le résumé",
    "label.phone": "Téléphoner",
    "label.fax": "Fax",
    "label.email": "E-mail",
    "label.website": "Site Internet",
    "button.send_money": "Envoyez de l'argent",
    "label.fx": "FX",
    "label.more_options_available": "Plus d'options de transfert disponibles",
    "label.send_method": "Mode d'envoi",
    "label.receive_method": "Mode de réception",
    "button.more_details": "Plus de détails",
    "label.no_additional_fees": "Pas de frais additionnels",
    "label.additional_fee": "* Des frais de réception peuvent s'appliquer",
    "onesignal.promptOptions.actionMessage":
      "Nous aimerions vous envoyer des notifications push. Vous pouvez vous désinscrire à tout moment.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "Ceci est un exemple de notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Les notifications apparaîtront sur votre bureau",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "Exemple de notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Les notifications apparaîtront sur votre appareil",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(Vous pouvez vous désabonner à tout moment)",
    "onesignal.promptOptions.acceptButton": "OUI",
    "onesignal.promptOptions.acceptButtonText": "OUI",
    "onesignal.promptOptions.cancelButton": "NON MERCI",
    "onesignal.promptOptions.cancelButtonText": "NON MERCI",
    "onesignal.welcomeNotification.title": "Bienvenue sur SaverAfrica",
    "onesignal.welcomeNotification.message": "Merci pour votre subscription!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Abonnez-vous aux notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "Vous êtes abonné aux notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "Vous avez bloqué les notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Merci pour votre subscription!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "Vous ne recevrez plus de notifications",
    "onesignal.notifyButton.text.dialogMainTitle":
      "Gérer les notifications du site",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "S'ABONNER",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "SE DÉSABONNER",
    "onesignal.notifyButton.text.dialogBlockedTitle":
      "Débloquer les notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Suivez ces instructions pour autoriser les notifications:",
  },
};
