import React, { useState, useEffect, useContext } from "react";
import Icon from "./../utils/Icon";
import { AppContext } from "./../App";

export default function LanguageSelect({ name, size }) {
  const { handleLanguageChange, languages, selectedLanguage } =
    useContext(AppContext);

  const defaultLanguage = languages.find(() => true);

  const [language, setLanguage] = useState([]);

  const COUNTRY_OPTIONS_KEY = "saverafrica.countryOptions";
  const COUNTRY_FROM_KEY = "saverafrica.countryFrom";
  const COUNTRY_TO_KEY = "saverafrica.countryTo";

  useEffect(() => {
    if (Array.isArray(selectedLanguage)) {
      setLanguage(defaultLanguage);
    } else {
      setLanguage(selectedLanguage);
    }

    localStorage.removeItem(COUNTRY_OPTIONS_KEY);
    localStorage.removeItem(COUNTRY_FROM_KEY);
    localStorage.removeItem(COUNTRY_TO_KEY);
  }, [selectedLanguage, defaultLanguage]);

  const { value, label } = language;

  const languageElements = languages.map((option) => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  });

  function handleSelectOnChange(changed) {
    const index = languages.findIndex((o) => o.value === changed);
    setLanguage(languages[index]);
    handleLanguageChange(languages.filter((o) => o.value === changed));
  }

  return (
    <div className={`select-control ${size}`}>
      <select
        name={name}
        value={value}
        onChange={(e) => handleSelectOnChange(e.target.value)}
      >
        {languageElements}
      </select>
      <span className="text">{label}</span>
      <Icon icon={"arrow_down"} />
    </div>
  );
}
