import "../assets/css/app.min.css";

import React, { useState, useEffect, Fragment } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import translation from "./translation";
import Loader from "./utils/Loader";
/* import pages */
import HomePage from "./home/index";
import Splash from "./page/Splash";
import CompareForm from "./compare/index";
import CompareList from "./compare/CompareList";
import Page from "./page/index";
import OperatorPage from "./page/OperatorPage";
import Resource from "./page/Resource";
import Video from "./page/Video";
import Calculators from "./page/Calculators";
import OvertimeCalculator from "./overtime/index";
import BudgetCalculator from "./budget/index";
import Faq from "./page/Faq";
import NotFound from "./page/NotFound";
import Settings from "./page/Settings";
import endpoint from "../api";
import ReactGA from 'react-ga-gtm';
import OneSignal from "react-onesignal";

export const AppContext = React.createContext();

const LANGUAGE_KEY = "saverafrica.language";
const CURRENCY_KEY = "saverafrica.currency";
const selectedSite = 2;

const offlineLanguage = [{ label: "English", value: "EN", currency: "AUD" }];

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
        ReactGA.initialize("GTM-MH7BS5B");
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
        ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};


export default function App({ location }) {
  const [languages, setLanguages] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [languageCode, setLanguageCode] = useState();
  const [loading, setLoading] = useState(true);

  const AppContextValue = {
    handleCurrencyChange,
    handleLanguageChange,
    currency,
    languages,
    selectedLanguage,
    selectedSite,
  };

  useGaTracker();

  useEffect(() => {
    endpoint
      .get(`/languages/`)
      .then((response) => {
        setLanguages(response.data);
      })
      .catch((err) => {
        setLanguages(offlineLanguage);
      });

    const currency = localStorage.getItem(CURRENCY_KEY);
    if (currency != null) setCurrency(currency);
  }, []);

  useEffect(() => {
    if (languages.length) {
      localStorage.setItem(LANGUAGE_KEY, JSON.stringify(selectedLanguage));
      setLanguageCode(selectedLanguage.value);
      setCurrency(selectedLanguage.currency);
    }

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [selectedLanguage]);

  useEffect(() => {
    const languageJSON = localStorage.getItem(LANGUAGE_KEY);

    if (languages.length) {
      if (languageJSON != null) {
        let languageObject = JSON.parse(languageJSON);
        setSelectedLanguage(languageObject);
      } else {
        let defaultLanguage = languages.find(() => true);
        setSelectedLanguage(defaultLanguage);
      }
    }
  }, [languages]);

  useEffect(() => {
    currency && localStorage.setItem(CURRENCY_KEY, currency);
  }, [currency]);

  useEffect(() => {
    document.body.className = "";

    if (languageCode != undefined) {
      document.body.classList.add(languageCode.toLowerCase());
    }
  }, [languageCode]);

  function handleLanguageChange(language) {
    setSelectedLanguage(language.find(() => true));
  }

  function handleCurrencyChange(newCurrency) {
    setCurrency(newCurrency);
  }

  useEffect(() => {
    if (languageCode != undefined) {
      OneSignal.init({
        appId: "c63a485b-de35-472a-88cf-bc92e9e23eda",
        safari_web_id:
          "web.onesignal.auto.27d2eba6-7621-43e8-b8d4-d2a9de3b8fea",
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          actionMessage:
            translation[languageCode]["onesignal.promptOptions.actionMessage"],
          exampleNotificationTitleDesktop:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationTitleDesktop"
            ],
          exampleNotificationMessageDesktop:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationMessageDesktop"
            ],
          exampleNotificationTitleMobile:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationTitleMobile"
            ],
          exampleNotificationMessageMobile:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationMessageMobile"
            ],
          exampleNotificationCaption:
            translation[languageCode][
              "onesignal.promptOptions.exampleNotificationCaption"
            ],
          acceptButton:
            translation[languageCode]["onesignal.promptOptions.acceptButton"],
          acceptButtonText:
            translation[languageCode][
              "onesignal.promptOptions.acceptButtonText"
            ],
          cancelButton:
            translation[languageCode]["onesignal.promptOptions.cancelButton"],
          cancelButtonText:
            translation[languageCode][
              "onesignal.promptOptions.cancelButtonText"
            ],
        },
        welcomeNotification: {
          title:
            translation[languageCode]["onesignal.welcomeNotification.title"],
          message:
            translation[languageCode]["onesignal.welcomeNotification.message"],
        },
        notifyButton: {
          text: {
            tipStateUnsubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateUnsubscribed"
              ],
            tipStateSubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateSubscribed"
              ],
            tipStateBlocked:
              translation[languageCode][
                "onesignal.notifyButton.text.tipStateBlocked"
              ],
            messageActionSubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.messageActionSubscribed"
              ],
            messageActionUnsubscribed:
              translation[languageCode][
                "onesignal.notifyButton.text.messageActionUnsubscribed"
              ],
            dialogMainTitle:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainTitle"
              ],
            dialogMainButtonSubscribe:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainButtonSubscribe"
              ],
            dialogMainButtonUnsubscribe:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogMainButtonUnsubscribe"
              ],
            dialogBlockedTitle:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogBlockedTitle"
              ],
            dialogBlockedMessage:
              translation[languageCode][
                "onesignal.notifyButton.text.dialogBlockedMessage"
              ],
          },
        },
      });
      OneSignal.setDefaultTitle("SaverAfricas");
    }
  }, [languageCode]);

  return (
    <>
      {!languageCode && <Loader />}
      {languageCode && (
        <IntlProvider
          locale={languageCode}
          messages={translation[languageCode]}
          textComponent={Fragment}
        >
          <AppContext.Provider value={AppContextValue}>
            <div className="app-container">
              {loading && <Loader />}
              {!loading && (
                <Switch>
                  <Route
                    location={location}
                    path="/compare"
                    exact
                    component={CompareForm}
                  />
                  <Route
                    location={location}
                    path="/compare/:currencyFrom/:countryFrom/:countryTo/:amount"
                    exact
                    component={CompareList}
                  />
                  <Route
                    location={location}
                    path="/page/:slug"
                    component={Page}
                  />
                  <Route
                    location={location}
                    path="/compare/:slug"
                    component={OperatorPage}
                  />
                  <Route
                    location={location}
                    path="/video"
                    exact
                    component={Video}
                  />
                  <Route
                    location={location}
                    path="/resource"
                    exact
                    component={Resource}
                  />
                  <Route
                    location={location}
                    path="/calculator"
                    exact
                    component={Calculators}
                  />
                  <Route
                    location={location}
                    path="/calculator/overtime"
                    exact
                    component={OvertimeCalculator}
                  />
                  <Route
                    location={location}
                    path="/calculator/budget"
                    exact
                    component={BudgetCalculator}
                  />
                  <Route
                    location={location}
                    path="/help"
                    exact
                    component={Faq}
                  />
                  <Route
                    location={location}
                    path="/settings"
                    exact
                    component={Settings}
                  />
                  <Route
                    location={location}
                    path="/home"
                    exact
                    component={HomePage}
                  />
                  <Route
                    location={location}
                    path="/"
                    exact
                    component={Splash}
                  />
                  <Route component={NotFound} />
                </Switch>
              )}
            </div>
          </AppContext.Provider>
        </IntlProvider>
      )}
    </>
  );
}
